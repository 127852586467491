import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "clutch/src/Card/Card.jsx";

import { readState } from "@/__main__/app-state.mjs";
import { IS_APP } from "@/__main__/constants.mjs";
import {
  GridContainer,
  Row,
  RowContainer,
  Tier,
} from "@/game-lol/TierList.style.jsx";
import AugmentTooltip from "@/game-tft/AugmentTooltip.jsx";
import ChampionImage from "@/game-tft/ChampionImage.jsx";
import { CombinedItemImage } from "@/game-tft/CommonComponents.jsx";
import {
  isFutureSet,
  isRevivalSet,
  showAugmentStats,
} from "@/game-tft/constants.mjs";
import getFilters from "@/game-tft/get-stats-filters.mjs";
import ItemTooltip from "@/game-tft/ItemToolTip.jsx";
import SelectSet from "@/game-tft/SelectSet.jsx";
import StaticTFT from "@/game-tft/static.mjs";
import StatsAugments, { RegExpTest } from "@/game-tft/StatsAugments.jsx";
import StatsChampions from "@/game-tft/StatsChampions.jsx";
import StatsItems from "@/game-tft/StatsItems.jsx";
import {
  ChampionBlock,
  ContainerStyles,
  HeaderStyles,
  TierGroup,
} from "@/game-tft/TierList.style.jsx";
import UnitToolTip from "@/game-tft/UnitToolTip.jsx";
import {
  useAugmentsByParams,
  useLocalizedAugments,
} from "@/game-tft/use-augments.mjs";
import { useLocalizedChampions } from "@/game-tft/use-champions.mjs";
import useSetByParam from "@/game-tft/use-set-by-param.mjs";
import LoadingSpinner from "@/inline-assets/loading-spinner.svg";
import Container from "@/shared/ContentContainer.jsx";
import { DataTableNoResults } from "@/shared/DataTable.jsx";
import getTierColor from "@/shared/get-tier-color.mjs";
import getTierIcon from "@/shared/get-tier-icon-path.mjs";
import { LoadingContainer } from "@/shared/InfiniteTable.style.jsx";
import PageContainer from "@/shared/PageContainer.jsx";
import PageHeader from "@/shared/PageHeader.jsx";
import { getLocale } from "@/util/i18n-helper.mjs";
import { useIsLoaded, useRoute } from "@/util/router-hooks.mjs";
import SuppressHydrationWarnings from "@/util/SuppressHydrationWarnings.jsx";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const TABS = {
  [undefined]: {
    url: () => `/tft/tierlist`,
    pageTitle: ["tft:meta.tierlist.title", "TFT Tier List"],
    title: ["tft:common:champions", "Champions"],
  },
  items: {
    url: () => `/tft/tierlist/items`,
    pageTitle: ["tft:meta.tierlist.title", "TFT Tier List"],
    title: ["tft:common:items", "Items"],
  },
};

if (IS_APP && showAugmentStats) {
  TABS.augments = {
    url: () => `/tft/tierlist/augments`,
    pageTitle: ["tft:meta.tierlist.title", "TFT Tier List"],
    title: ["tft:common:augments", "Augments"],
  };
}

const TierList = () => {
  const { t } = useTranslation();
  const isLoaded = useIsLoaded();
  const {
    parameters: [tab],
  } = useRoute();

  const currTab = TABS[tab];

  if (!currTab) return <h1>{tab}</h1>;

  return (
    <PageContainer>
      <HeaderStyles>
        <PageHeader
          title={
            currTab.pageTitle?.length
              ? t([...currTab.pageTitle])
              : t("common:navigation.tierList", "Tier List")
          }
          links={Object.values(TABS).map(({ url, title }) => ({
            url: typeof url === "function" ? url() : url,
            text: title,
          }))}
          aside={<SelectSet />}
        />
      </HeaderStyles>

      <ContainerStyles>
        <Container className={"tierlist-content"}>
          <Filters tab={tab} />
          {isLoaded ? (
            tab === "augments" ? (
              <AugmentList />
            ) : tab === "items" ? (
              <ItemsList />
            ) : (
              <ChampionList />
            )
          ) : (
            <LoadingContainer>
              <LoadingSpinner />
            </LoadingContainer>
          )}
        </Container>
      </ContainerStyles>
    </PageContainer>
  );
};

export default TierList;

const Filters = ({ tab }) => {
  return tab === "augments" ? (
    <AugmentsFilter />
  ) : tab === "items" ? (
    <ItemsFilter />
  ) : (
    <ChampionsFilter />
  );
};

const ChampionsFilter = () => {
  const { FilterBar: ChampionsFilterBar } = StatsChampions;
  const FilterBar = ChampionsFilterBar();
  return <div className="flex align-center gap-2 wrap">{FilterBar}</div>;
};

const AugmentsFilter = () => {
  const { FilterBar: AugmentsFilterBar } = StatsAugments;
  const FilterBar = AugmentsFilterBar();
  return <div className="flex align-center gap-2 wrap">{FilterBar}</div>;
};

const ItemsFilter = () => {
  const { FilterBar: ItemsFilterBar } = StatsItems;
  const FilterBar = ItemsFilterBar();
  return <div className="flex align-center gap-2 wrap">{FilterBar}</div>;
};

function ChampionList() {
  const { searchParams } = useRoute();
  const set = useSetByParam(true);
  const state = useSnapshot(readState);
  const isSkinsEnabled = state.settings.tft.isSkinsEnabled;

  const data = useChampionStats({ searchParams, set });

  return (
    <Card padding={0}>
      <div>
        {!data?.length || data instanceof Error ? (
          <DataTableNoResults />
        ) : (
          <SuppressHydrationWarnings>
            <ol style={{ paddingBlockEnd: "var(--sp-6)" }}>
              {data.map((group, index) => {
                const TierIcon = getTierIcon(index + 1);

                return (
                  <RowContainer key={index}>
                    <Row style={{ "--tier-color": getTierColor(index + 1) }}>
                      <Tier>
                        {TierIcon && (
                          <img
                            className="tier-icon"
                            src={TierIcon}
                            width="28"
                            height="28"
                          />
                        )}
                      </Tier>
                      <GridContainer>
                        <TierGroup>
                          {group
                            .sort((a, b) => a.avg_placement - b.avg_placement)
                            .map((c, i) => {
                              return (
                                <Champion
                                  key={`${c.championKey}_${i}`}
                                  champion={c}
                                  isSkinsEnabled={isSkinsEnabled}
                                  set={set}
                                />
                              );
                            })}
                        </TierGroup>
                      </GridContainer>
                    </Row>
                  </RowContainer>
                );
              })}
            </ol>
          </SuppressHydrationWarnings>
        )}
      </div>
    </Card>
  );
}

const Champ = ({ champion, isSkinsEnabled, set }) => {
  if (!champion) return null;

  return (
    <SuppressHydrationWarnings>
      <ChampionBlock>
        <a
          href={`/tft/champion/${champion.apiKey}?set=${set.replace(
            "set",
            "",
          )}`}
        >
          <UnitToolTip
            champInfo={champion}
            set={set}
            skinSetting={isSkinsEnabled}
            style={{ zIndex: "3" }}
            className="flex column gap-1"
            stats={{
              pickRate: champion.pick_rate,
              avgPlace: champion.avg_placement,
            }}
          >
            <ChampionImage
              champKey={champion?.apiKey}
              cost={champion?.cost}
              size={50}
              set={set}
              skinSetting={isSkinsEnabled}
            />
            <div className="meta">
              <p
                className="name type-caption--bold"
                style={{ whiteSpace: "normal" }}
              >
                {champion.name}
              </p>
            </div>
          </UnitToolTip>
        </a>
      </ChampionBlock>
    </SuppressHydrationWarnings>
  );
};
const Champion = React.memo(Champ);

const useChampionStats = ({ searchParams, set }) => {
  const state = useSnapshot(readState);
  const unitFilters = getFilters(
    searchParams,
    "champions",
    isFutureSet(set),
    isRevivalSet(set),
  );
  const champions = state.tft[set]?.champions;
  const units = state.tft.stats.units?.[unitFilters]?.[set];
  const selectedCost = searchParams.get("cost") || "ALL";
  const selectedTrait = searchParams.get("trait") || "all";
  const search = searchParams.get("search");
  const localizedChampions = useLocalizedChampions(set);

  const unitStats = useMemo(() => {
    const data = units;
    if (data && Object.keys(data)?.length && !(data instanceof Error)) {
      let result = Object.keys(data).map((key) => {
        return {
          key: key,
          href: `/tft/champion/${key}?set=${set.replace("set", "")}`,
          ...localizedChampions?.[key],
          ...data[key],
        };
      });

      if (selectedCost !== "ALL") {
        result = result.filter(
          (unit) => champions[unit.key]?.cost === parseInt(selectedCost),
        );
      }

      if (selectedTrait !== "all") {
        result = result.filter((unit) => {
          const unitData = champions[unit.key];
          const unitTraits = [];
          if (unitData)
            for (const key of ["class", "origin"])
              if (Array.isArray(unitData[key]))
                unitTraits.push(...unitData[key]);
          return unitTraits.includes(selectedTrait);
        });
      }

      // search filter
      if (search?.length > 1) {
        result = result.filter(({ key: apiKey }) => {
          const localizedName = localizedChampions?.[apiKey]?.name;
          const name = StaticTFT.getChampInfo(apiKey, set)?.name;
          return (
            localizedName?.toLowerCase()?.includes(search.toLowerCase()) ||
            name?.toLowerCase()?.includes(search.toLowerCase())
          );
        });
      }

      return getTiersArray(result);
    }
    return typeof data !== "object" || data instanceof Error ? [] : data;
  }, [
    units,
    selectedCost,
    selectedTrait,
    search,
    set,
    localizedChampions,
    champions,
  ]);

  return unitStats;
};

function AugmentList() {
  const { searchParams } = useRoute();
  const set = useSetByParam(true);

  const data = useAugmentStats({ searchParams, set });

  return (
    <Card padding={0}>
      <div>
        {!data?.length || data instanceof Error ? (
          <DataTableNoResults />
        ) : (
          <SuppressHydrationWarnings>
            <ol style={{ paddingBlockEnd: "var(--sp-6)" }}>
              {data.map((group, index) => {
                const TierIcon = getTierIcon(index + 1);

                return (
                  <RowContainer key={index}>
                    <Row style={{ "--tier-color": getTierColor(index + 1) }}>
                      <Tier>
                        {TierIcon && (
                          <img
                            className="tier-icon"
                            src={TierIcon}
                            width="28"
                            height="28"
                          />
                        )}
                      </Tier>
                      <GridContainer>
                        <TierGroup>
                          {group
                            .sort((a, b) => a.avg_placement - b.avg_placement)
                            .map((c, i) => {
                              return (
                                <ChampionBlock key={c.name + i}>
                                  <a>
                                    <AugmentTooltip
                                      augment={c.key}
                                      stats={{
                                        pickRate: c.pick_rate,
                                        avgPlace: c.avg_placement,
                                      }}
                                    >
                                      <div className="flex column gap-1">
                                        <img
                                          src={StaticTFT.getAugmentImage(
                                            c.key,
                                            set,
                                          )}
                                          alt={c.name}
                                          width={60}
                                          height={60}
                                        />
                                        <p
                                          className="name type-caption--bold"
                                          style={{ whiteSpace: "normal" }}
                                        >
                                          {c.name}
                                        </p>
                                      </div>
                                    </AugmentTooltip>
                                  </a>
                                </ChampionBlock>
                              );
                            })}
                        </TierGroup>
                      </GridContainer>
                    </Row>
                  </RowContainer>
                );
              })}
            </ol>
          </SuppressHydrationWarnings>
        )}
      </div>
    </Card>
  );
}

const useAugmentStats = ({ searchParams, set }) => {
  const state = useSnapshot(readState);
  const augments = state.tft[set]?.augments;
  const augmentsFilters = getFilters(
    searchParams,
    "augments",
    isFutureSet(set),
    isRevivalSet(set),
  );
  const augmentsStats = state.tft.stats.augments[augmentsFilters]?.[set];
  const type = searchParams.get("type") ?? "ALL";
  const tier = searchParams.get("tier") ?? "ALL";
  const search = searchParams.get("search");
  const localizedAugments = useLocalizedAugments(set);
  let items = useAugmentsByParams({
    augments,
    augmentsStats,
    localizedAugments,
    set,
    type,
  });

  // tier filter
  items =
    tier && tier !== "ALL"
      ? items.filter((i) => StaticTFT.getAugmentTier(i.key, set) === tier)
      : items;

  // search filter
  if (search?.length > 1) {
    const instance = RegExp(search, "i");
    items = items.filter(
      ({ name, key, champion }) =>
        instance.test(name ?? key) ||
        instance.test(champion) ||
        RegExpTest.some((arr) => instance.test((name ?? key).replace(...arr))),
    );
  }

  return getTiersArray(items);
};

function ItemsList() {
  const { searchParams } = useRoute();
  const set = useSetByParam(true);

  const data = useItemStats({ searchParams, set });

  return (
    <Card padding={0}>
      <div>
        {!data?.length || data instanceof Error ? (
          <DataTableNoResults />
        ) : (
          <SuppressHydrationWarnings>
            <ol style={{ paddingBlockEnd: "var(--sp-6)" }}>
              {data.map((group, index) => {
                const TierIcon = getTierIcon(index + 1);

                return (
                  <RowContainer key={index}>
                    <Row style={{ "--tier-color": getTierColor(index + 1) }}>
                      <Tier>
                        {TierIcon && (
                          <img
                            className="tier-icon"
                            src={TierIcon}
                            width="28"
                            height="28"
                          />
                        )}
                      </Tier>
                      <GridContainer>
                        <TierGroup>
                          {group
                            .sort((a, b) => a.avg_placement - b.avg_placement)
                            .map((c, i) => {
                              return (
                                <ChampionBlock key={c.name + i}>
                                  <a>
                                    <ItemTooltip
                                      item={c.key}
                                      stats={{
                                        pickRate: c.pick_rate,
                                        avgPlace: c.avg_placement,
                                      }}
                                      className="flex column gap-1"
                                    >
                                      <CombinedItemImage
                                        size={"50px"}
                                        src={StaticTFT.getItemImage(c.key, set)}
                                      />
                                      <p
                                        className="name type-caption--bold"
                                        style={{ whiteSpace: "normal" }}
                                      >
                                        {c.name}
                                      </p>
                                    </ItemTooltip>
                                  </a>
                                </ChampionBlock>
                              );
                            })}
                        </TierGroup>
                      </GridContainer>
                    </Row>
                  </RowContainer>
                );
              })}
            </ol>
          </SuppressHydrationWarnings>
        )}
      </div>
    </Card>
  );
}

const useItemStats = ({ searchParams, set }) => {
  const state = useSnapshot(readState);

  const itemStaticData = state.tft[set]?.items;
  const itemFilters = getFilters(
    searchParams,
    "items",
    isFutureSet(set),
    isRevivalSet(set),
  );
  const search = searchParams.get("search");
  const kind = searchParams.get("kind") ?? "normal";
  const locale = getLocale();
  const localizedItems = state.tft[set]?.localizedItems?.[locale];

  const itemsStats = useMemo(() => {
    const data = state.tft.stats.items?.[itemFilters]?.[set];
    if (data && Object.keys(data)?.length >= 5) {
      let result = Object.keys(data).map((apiKey) => {
        const key = StaticTFT.getItemKey(apiKey, set);
        return {
          ...data[apiKey],
          id: apiKey,
          key: key,
          name:
            localizedItems?.[apiKey]?.name ??
            itemStaticData?.[apiKey]?.name ??
            itemStaticData?.[key]?.name,
        };
      });

      // kind filter
      if (kind !== "all") {
        if (kind === "normal")
          result = result.filter((item) => item.item_type === "other");
        if (kind === "emblem")
          result = result.filter((item) => item.item_type === "emblem");
        if (kind === "ornn")
          result = result.filter((item) => item.item_type === "ornn");
        if (kind === "radiant")
          result = result.filter((item) => item.item_type === "radiant");
      }

      // search filter
      if (search?.length > 1) {
        result = result.filter(({ id: apiKey, name }) => {
          const localizedName = localizedItems?.[apiKey]?.name;
          return (
            localizedName?.toLowerCase()?.includes(search.toLowerCase()) ||
            name?.toLowerCase()?.includes(search.toLowerCase())
          );
        });
      }

      return getTiersArray(result);
    }
    return typeof data !== "undefined" ? [] : data;
  }, [
    itemFilters,
    itemStaticData,
    kind,
    localizedItems,
    search,
    set,
    state.tft.stats.items,
  ]);

  return itemsStats;
};

const getTiersArray = (data) => {
  const tiers = [];
  data.forEach((obj) => {
    if (!tiers[parseInt(obj.tier) - 1]) {
      tiers[parseInt(obj.tier) - 1] = [];
    }
    tiers[parseInt(obj.tier) - 1].push(obj);
  });
  return tiers;
};

export function meta(params) {
  const tabId = params[0];

  let title;
  let description;
  const subtitle = true;

  switch (tabId) {
    default:
      title = ["tft:meta.tierlist.title", "TFT Tier List"];
      description = [
        "tft:meta.tierlist.description",
        "Discover the best TFT champions, augments, item builds, and more with Blitz.",
      ];
  }

  return {
    title,
    description,
    subtitle,
  };
}
