import { styled } from "goober";
import { mobile, tablet } from "clutch/src/Style/style.mjs";

export const TierGroup = styled("ol")`
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-auto-rows: auto;
  flex: 1;

  @container grid-container (inline-size <= 820px) {
    grid-template-columns: repeat(8, 1fr);
  }
  @container grid-container (inline-size <= 650px) {
    grid-template-columns: repeat(6, 1fr);
  }
  @container grid-container (inline-size <= 475px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const ChampionBlock = styled("li")`
  > a {
    position: relative;
    display: flex;
    align-items: center;
    gap: var(--sp-4);
    padding: var(--sp-2) var(--sp-3);
    border-radius: var(--br);

    &:hover {
      background: var(--shade6);

      .champion-role {
        background: var(--shade6);
      }
    }
  }

  &:hover {
    img {
      filter: brightness(1.4);
    }
  }

  .name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 9ch;

    @container grid-container (inline-size <= 350px) {
      max-width: unset;
    }
  }
`;

export const HeaderStyles = styled("div")`
  ${tablet} {
    .inner-container {
      flex-direction: column;
      align-items: flex-start;

      .right {
        width: 50%;
      }

      ${mobile} {
        align-items: center;
        .right {
          width: 100%;
          padding: 0 10%;

          .selectButton .text {
            max-width: 100%;
          }
        }
      }
    }
  }
`;

export const ContainerStyles = styled("div")`
  .tierlist-content {
    display: flex;
    flex-direction: column;
    gap: var(--sp-3);
  }
`;
